import SearchIcon from "@mui/icons-material/Search";
import { usersApi } from '../redux/usersApi';
import {
  AppBar,
  Box,
  CircularProgress,
  InputBase,
  Button as MuiButton,
  Toolbar,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import {AuthContext} from "./AuthContext";
import { useDispatch } from "react-redux";
import { setSearchQuery as setSearchQueryActionCreator } from "../redux/uiSlice";
import { useGetMeQuery } from "../redux/usersApi";

import { Role } from "../typings/enums";
import CreateUserDialog from "./CreateUserDialog";
import { withHidden } from "./withHidden";
import { useNavigate } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
}));

export const Button = withHidden(MuiButton);

const MenuBar = () => {

  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);

  const { data, isError, refetch } = useGetMeQuery();

  if(data) {
    console.log(data.neighbours);
  }
  const navigate = useNavigate();

  useEffect(() => {
    refetch();

    if (isError) {
      navigate("/");
    }
  }, [isError, navigate, refetch]);
  const dispatch = useDispatch();
  const setSearchQuery = (query: string) =>
    dispatch(setSearchQueryActionCreator(query));

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const authContext = useContext(AuthContext);
  let orgName = data?.orgName;

  if(data?.role === Role.CardIssuer) {
    orgName = 'Kartenlieferant';
  }

  if(data?.role === Role.SuperAdmin) {
    orgName = 'SuperAdmin';
  }

  if (!authContext) {
    // Handle the case where authContext is not available
    console.error('AuthContext is not available');
    return <div>Error: AuthContext not found</div>;
  }
  const {setToken} = authContext;
  const handleLogout = () => {

    // Invalidate RTK Query cache or reset it
    dispatch(usersApi.util.resetApiState());

    setToken(null); // Clear the token from context
    localStorage.removeItem('token'); // Remove the token from local storage
    navigate('/'); // Navigate to the login page
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ display: { xs: "none", sm: "block", minWidth: 30 } }}
        >
          {orgName ?? (
            <CircularProgress
              size={30}
              className="mt-2"
              sx={{ color: "white" }}
            />
          )}
        </Typography>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Suche nach Name, Adresse, Telefonnummer,..."
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          hidden={!data?.role || data?.role === Role.CardIssuer}
          variant="outlined"
          color={"inherit"}
          onClick={() => setCreateUserDialogOpen(true)}
        >
          Antrag erfassen
        </Button>

        <Button
            className={"ml-5"}
            variant="outlined"
            color="inherit"
            onClick={handleLogout}
        >
          Logout
        </Button>
      </Toolbar>
      <CreateUserDialog
        open={createUserDialogOpen}
        handleClose={() => setCreateUserDialogOpen(false)}
        isLoading={false}
        orgId={data?.orgId ?? ""}
      />
    </AppBar>
  );
};

export default MenuBar;
