import { LockOutlined } from "@mui/icons-material";
import { Box, CssBaseline, Avatar, Button, Typography, TextField, Container } from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Home = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [infoMessage, setInfoMessage] = useState<string | null>(null);
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [resetEmail, setResetEmail] = useState<string>("");
    const [showResetForm, setShowResetForm] = useState<boolean>(false);

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    if (!authContext) {
        console.error('AuthContext is not available');
        return <div>Error: AuthContext nicht gefunden</div>;
    }

    const { setToken } = authContext;

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('client_id', import.meta.env.VITE_CLIENT_ID);
        params.append('client_secret', import.meta.env.VITE_CLIENT_SECRET);
        params.append('scope', 'admin');
        params.append('username', username);
        params.append('password', password);

        e.preventDefault();
        try {
            const response = await axios.post(
                `${import.meta.env.VITE_API_URL}oauth/token`,
                params,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );

            console.log(response.data);
            if (!response.data.scope.includes('admin')) {
                setErrorMessage("Sie haben keine Berechtigung, diese Seite zu besuchen.");
                return;
            }

            console.log('response.data:', response.data);
            setToken(response.data.access_token);
            localStorage.setItem("token", response.data.access_token);
            navigate("/dashboard");
        } catch (error) {
            localStorage.removeItem("token");

            if (axios.isAxiosError(error) && error.response) {
                if(error.response.status === 401) {
                    setErrorMessage("Ungültige Anmeldeinformationen. Bitte versuchen Sie es erneut.");
                } else {
                    setErrorMessage(error.response.data.message || "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
                }
            } else {
                console.error(error);
                setErrorMessage("Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
            }
        }
    };

    const handleForgotPassword = async () => {
        try {
            await axios.post(`${import.meta.env.VITE_API_URL}users/forgotpassword/request`, {
                userName: resetEmail,
            });
            setInfoMessage("Eine E-Mail zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.");
        } catch (error) {
            console.error('Forgot password error:', error);
            setErrorMessage("Fehler beim Senden der E-Mail zum Zurücksetzen des Passworts. Bitte versuchen Sie es erneut.");
        }
    };

    return (
        <Container maxWidth="xs">
            <CssBaseline />
            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlined />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Anmelden
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Benutzername"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit}
                    >
                        Anmelden
                    </Button>
                    <Typography sx={{ mt: 2 }} component="div">
                        <Button color="primary" onClick={() => setShowResetForm(!showResetForm)}>
                            Passwort vergessen?
                        </Button>
                        {showResetForm && (
                            <>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    name="resetEmail"
                                    label="E-Mail zum Zurücksetzen"
                                    type="email"
                                    id="resetEmail"
                                    autoComplete="email"
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                />
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={handleForgotPassword}
                                >
                                    E-Mail zum Zurücksetzen senden
                                </Button>
                            </>
                        )}
                    </Typography>
                    {errorMessage && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {errorMessage}
                        </Typography>
                    )}
                    {infoMessage && (
                        <Typography color="info" sx={{ mt: 2 }}>
                            {infoMessage}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default Home;
