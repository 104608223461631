import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  MoveUserRequestDTO,
  UpdateUserRequestDTO,
  useGetMeQuery,
} from "../redux/usersApi";
import MoveToPartnerCityForm from "./MoveToPartnerCityForm";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface MoveToPartnerCityProps {
  handleClose: () => void;
  open: boolean;
  isLoading: boolean;
  userId: string;
  handleSubmitMove: (
    data: Omit<MoveUserRequestDTO, "applicationStatus">
  ) => void;
}

export default function MoveToPartnerCityDialog(props: MoveToPartnerCityProps) {
  const { currentData: currentOrgData, isError } = useGetMeQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  const formik = useFormik<
    Pick<UpdateUserRequestDTO, "address" | "postalCode">
  >({
    initialValues: {},
    isInitialValid: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      address: Yup.string().required("Bitte geben Sie eine Adresse ein"),
      postalCode: Yup.string().required("Bitte geben Sie eine PLZ ein"),
    }),
    onSubmit: () => void 0,
  });

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      fullWidth
    >
      <DialogTitle>Umzug Partnergemeinde</DialogTitle>
      <DialogContent>
        <MoveToPartnerCityForm
          formik={formik}
          tenant={currentOrgData?.orgName ?? ""}
          neighbours={currentOrgData?.neighbours ?? []}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Abbrechen</Button>
        <LoadingButton
          variant="contained"
          loading={props.isLoading}
          onClick={() => {
            const { address, postalCode } = formik.values;
            if (address && postalCode) {
              props.handleSubmitMove({
                address,
                postalCode,
              });
            }
          }}
          disabled={formik.isValid === false || formik.dirty === false}
        >
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
