import { Box, Button } from "@mui/material";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { ChangeEvent } from "react";

import useDownscale from "../hooks/useDownscale";

export interface PictureUploaderProps {
  picture?: string;
  handleAttatchFile: (base64Image: string) => void;
}

const PictureUploader = (props: PictureUploaderProps) => {
  const { scaleCropImage } = useDownscale();
  const handleAddFile = async (e: ChangeEvent<HTMLInputElement>) => {
    // Picture comes in as a file
    const file = e.target.files?.[0];
    // Downscale the image
    if (file) {
      const scaledImage = await scaleCropImage(file);
      // Convert to base64
      const base64Image = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(scaledImage);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

      props.handleAttatchFile(base64Image);
    }
  };

  return (
    <Box
      className="w-full rounded-md"
      sx={{
        backgroundImage: `url(${props.picture})`,
        backgroundSize: "contain",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        minHeight: "300px",
      }}
    >
      <Button
        variant="outlined"
        component="label"
        fullWidth
        size="large"
        sx={{
          opacity: props.picture ? "0%" : "100%",
          height: "100%",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.1)",
            opacity: "100%",
          },
        }}
      >
        <FileUploadIcon
          htmlColor={props.picture ? "white" : "darkgray"}
          fontSize="large"
        />
        <input
          type="file"
          accept="image/*"
          title=" "
          hidden
          onChange={handleAddFile}
        />
      </Button>
    </Box>
  );
};

export default PictureUploader;
