import { Grid, TextField, MenuItem } from "@mui/material";
import { FormikProps } from "formik";
import { GetMeResponseDTO, UpdateUserRequestDTO } from "../redux/usersApi";
import { ChangeEvent } from "react";

const MoveToPartnerCityForm = (props: {
  tenant: string;
  formik: FormikProps<
    Partial<Pick<UpdateUserRequestDTO, "address" | "postalCode">>
  >;
  neighbours: GetMeResponseDTO["neighbours"];
}) => {
  const availablePostalCodes = props.neighbours;

  const formHelperTextProps = {
    sx: {
      "&.Mui-error": {
        marginLeft: 0,
        paddingLeft: 1,
        height: 0,
        textWrap: "nowrap",
      },
    },
  };

  const handleFieldChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.formik.setFieldValue(e.target.name, e.target.value, false);
    setTimeout(
      () => props.formik.setFieldTouched(e.target.name, true, true),
      0
    );
  };

  return (
    <Grid>
      <Grid container rowSpacing={3} columnSpacing={2} item xs={12}>
        <Grid item xs={6}>
          <TextField
            FormHelperTextProps={formHelperTextProps}
            error={
              !!props.formik.touched.address && !!props.formik.errors.address
            }
            fullWidth
            helperText={
              props.formik.touched.address && props.formik.errors.address
            }
            InputProps={{ disableUnderline: true }}
            label="Adresse"
            name="address"
            onChange={handleFieldChange}
            required
            type="text"
            value={props.formik.values.address ?? ""}
            variant="filled"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            FormHelperTextProps={formHelperTextProps}
            error={
              !!props.formik.touched.postalCode &&
              !!props.formik.errors.postalCode
            }
            fullWidth
            helperText={
              props.formik.touched.postalCode && props.formik.errors.postalCode
            }
            InputProps={{ disableUnderline: true }}
            label="PLZ"
            name="postalCode"
            onChange={(e) => {
              handleFieldChange({
                target: {
                  name: "postalCode",
                  value: e.target.value,
                },
              } as ChangeEvent<HTMLInputElement>);

              handleFieldChange(({
                target: {
                  name: "city",
                  value: availablePostalCodes[
                      e.target.value as unknown as keyof typeof availablePostalCodes
                      ],
                },
              } as unknown) as ChangeEvent<HTMLInputElement>);
            }}
            required
            select
            SelectProps={{ native: false }}
            value={props.formik.values?.postalCode ?? ""}
            variant="filled"
          >
            {Object.keys(availablePostalCodes).map((postalCode) => (
              <MenuItem key={postalCode} value={parseInt(postalCode, 10)}>
                {postalCode}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            FormHelperTextProps={formHelperTextProps}
            fullWidth
            InputProps={{ disableUnderline: true }}
            label="Ort"
            name="city"
            disabled
            value={
              availablePostalCodes[
                parseInt(
                  // FIXME: Refine types
                  props.formik.values?.postalCode as unknown as string,
                  10
                ) as keyof typeof availablePostalCodes
              ] ?? ""
            }
            variant="filled"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MoveToPartnerCityForm;
