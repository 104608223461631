import { MenuItem, TextField } from "@mui/material";
import { CardStatus } from "../typings/enums";
import { cardStatusLabels } from "./labels";
import { FormikProps } from "formik";
import { GetOneUserResponseDTO } from "../redux/usersApi";

export interface CardStatusSelectProps {
  formik: FormikProps<Partial<GetOneUserResponseDTO>>;
  isCardIssuer: boolean;
  isBulkEdit?: boolean;
}

const CardStatusSelect = (props: CardStatusSelectProps) => {
  const allowOption = (targetState: CardStatus, currentState: CardStatus) => {
    if (props.isBulkEdit) {
      const allowedStatus = {
        cardIssuer: [CardStatus.NotPrinted, CardStatus.Printed],
        tenant: [CardStatus.Assigned, CardStatus.Sent],
      }[props.isCardIssuer ? "cardIssuer" : "tenant"];

      return (
        allowedStatus.includes(targetState) &&
        allowedStatus.includes(currentState)
      );
    } else {
      const allowedStatus = {
        cardIssuer: [
          CardStatus.NotPrinted,
          CardStatus.Printed,
          CardStatus.Assigned,
        ],
        tenant: [CardStatus.Assigned, CardStatus.Sent],
      }[props.isCardIssuer ? "cardIssuer" : "tenant"];

      return (
        allowedStatus.includes(targetState) &&
        allowedStatus.includes(currentState)
      );
    }
  };

  return (
    <TextField
      error={
        props.formik.touched?.cardStatus && !!props.formik.errors.cardStatus
      }
      fullWidth
      helperText={
        props.formik.touched?.cardStatus && props.formik.errors.cardStatus
      }
      InputProps={{ disableUnderline: true }}
      label="Kartenstatus"
      name="cardStatus"
      onChange={(e) => {
        props.formik.setFieldValue(e.target.name, e.target.value, false);
        props.formik.setFieldTouched(e.target.name, true, false);
      }}
      required
      select
      SelectProps={{ native: false }}
      value={props.formik.values.cardStatus ?? ""}
      variant="filled"
    >
      {Object.entries(cardStatusLabels).map(([key, value]) => {
        const disableOption = !allowOption(
          key as CardStatus,
          props.formik.values.cardStatus as CardStatus
        );

        return (
          <MenuItem key={key} value={key} disabled={disableOption}>
            {value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CardStatusSelect;
