import { SliceCaseReducers, createSlice } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";

export interface AuthState {
  userData: User | null;
  isLoading: boolean;
}

const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
  name: "auth",
  initialState: {
    userData: null,
    isLoading: true,
  },
  reducers: {
    setUserData: (state, action) => {
      return {
        ...state,
        userData: action.payload,
      };
    },
    setIsLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const { setUserData, setIsLoading } = authSlice.actions;
export const authReducer = authSlice.reducer;
