import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography } from '@mui/material';
import ResetPasswordForm from '../components/ResetPasswordForm';

function TokenValidation() {
    const { token } = useParams<{ token?: string }>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isValidToken, setIsValidToken] = useState(false);

    const userName = searchParams.get('userName') ?? '';

    useEffect(() => {
        if (!token || !userName) {
            console.error('Token or username is missing.');
            navigate('/');
            return;
        }
        axios.post(`${import.meta.env.VITE_API_URL}users/forgotpassword/check`, { token, userName })
            .then(() => {
                setIsValidToken(true);
            })
            .catch((error) => {
                console.error('Invalid token or username:', error);
                navigate('/');
            });
    }, [token, userName, navigate]);

    if (!isValidToken) {
        return <Container><Typography>Validating token...</Typography></Container>;
    }

    if (!token) {
        return <Container><Typography>Error: Token is missing.</Typography></Container>;
    }

    return <ResetPasswordForm token={token} userName={userName} />;
}

export default TokenValidation;
