import { MenuItem, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { GetOneUserResponseDTO } from "../redux/usersApi";

export interface OrgProps {
  formik: FormikProps<Partial<GetOneUserResponseDTO>>;
  orgs: string[];
  isSuperAdmin: boolean;
  isBulkEdit?: boolean;
}

const OrgSelect = (props: OrgProps) => {

  return (
    <TextField
      error={
        props.formik.touched?.org && !!props.formik.errors.org
      }
      fullWidth
      helperText={
        props.formik.touched?.org && props.formik.errors.org
      }
      InputProps={{ disableUnderline: true }}
      label="Org"
      name="orgAdmin"
      onChange={(e) => {
        props.formik.setFieldValue(e.target.name, e.target.value, false);
        props.formik.setFieldTouched(e.target.name, true, false);
      }}
      required
      select
      SelectProps={{ native: false }}
      value={props.formik.values.orgAdmin ?? ""}
      variant="filled"
    >
      {Object.entries(props.orgs).map(([key, value]) => {
        return (
          <MenuItem key={key} value={value}>
            {value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default OrgSelect;
