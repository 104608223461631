import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface UiSlice {
  searchQuery: string;
}

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    searchQuery: "",
  },
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        searchQuery: action.payload,
      };
    },
  },
});

export const { setSearchQuery } = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
