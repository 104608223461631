import {GetManyUsersResponseElementDTO} from "../redux/usersApi.js";
import {jsPDF} from "jspdf";
import "../utils/cardPdfCreation/HelveticaNowText-Regular-normal.js";
import "../utils/cardPdfCreation/HelveticaNowText-Bold-normal.js";
import {useCallback, useMemo} from "react";
import * as faceapi from 'face-api.js';
import EXIF from 'exif-js';

export const useCreatePdf = () => {

  async function loadModels() {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/models');  // Adjust path as needed
  }

  async function detectFace(image: HTMLImageElement): Promise<faceapi.Box | null> {
    const detection = await faceapi.detectSingleFace(image, new faceapi.TinyFaceDetectorOptions());
    if (detection) {
      return detection.box;
    }
    return null;
  }

    function cropToRatio(
        canvas: HTMLCanvasElement,
        targetWidth: number,
        targetHeight: number
    ): string {
        const ctx = canvas.getContext('2d');
        if (!ctx) return '';

        const aspectRatio = targetWidth / targetHeight;
        const currentAspectRatio = canvas.width / canvas.height;

        let sx = 0, sy = 0, sw = canvas.width, sh = canvas.height;

        if (currentAspectRatio > aspectRatio) {
            // Image is too wide, crop the sides
            const newWidth = canvas.height * aspectRatio;
            sx = (canvas.width - newWidth) / 2;
            sw = newWidth;
        } else {
            // Image is too tall, crop the top and bottom
            const newHeight = canvas.width / aspectRatio;
            sy = (canvas.height - newHeight) / 2;
            sh = newHeight;
        }

        const croppedCanvas = document.createElement('canvas');
        croppedCanvas.width = targetWidth;
        croppedCanvas.height = targetHeight;

        const croppedCtx = croppedCanvas.getContext('2d');
        croppedCtx?.drawImage(canvas, sx, sy, sw, sh, 0, 0, targetWidth, targetHeight);

        return croppedCanvas.toDataURL();
    }

  function correctOrientation(canvas: HTMLCanvasElement, img: HTMLImageElement, orientation: number) {
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    switch (orientation) {
      case 3:
        ctx.rotate((180 * Math.PI) / 180);
        ctx.drawImage(img, -img.width, -img.height, img.width, img.height);
        break;
      case 6:
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((90 * Math.PI) / 180);
        ctx.drawImage(img, 0, -img.height);
        break;
      case 8:
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.drawImage(img, -img.width, 0);
        break;
      default:
        ctx.drawImage(img, 0, 0);
        break;
    }
  }


  function getOrientation(image: HTMLImageElement): Promise<number> {
    return new Promise((resolve) => {
      try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        EXIF.getData(image, function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
          const orientation = EXIF.getTag(this, 'Orientation');
          resolve(orientation || 1);  // Default to orientation 1 (no rotation) if EXIF data is not available
        });
      } catch (error) {
        console.info('EXIF data not available or corrupted, using default orientation:', error);
        resolve(1);  // Default to no rotation if EXIF data is not available or corrupted
      }
    });
  }

  function cropToFitFace(
      canvas: HTMLCanvasElement,
      faceBox: faceapi.Box,
      targetWidth: number,
      targetHeight: number
  ): string {
    const ctx = canvas.getContext('2d');
    if (!ctx) return '';

    const aspectRatio = targetWidth / targetHeight;

    // Add extra space for the entire head (forehead, chin, etc.)
    const headPaddingTop = faceBox.height * 0.8;  // 60% of the face height above the face
    const headPaddingBottom = faceBox.height * 0.3;  // 30% of the face height below the chin

    // Compute the total crop dimensions (including padding)
    const cropHeight = faceBox.height + headPaddingTop + headPaddingBottom;
    const cropWidth = cropHeight * aspectRatio;  // Maintain aspect ratio

    // Compute the coordinates of the crop area, ensuring the face stays centered
    let sx = faceBox.x + faceBox.width / 2 - cropWidth / 2;
    let sy = faceBox.y - headPaddingTop;  // Start above the face to include the forehead

    // Ensure cropping doesn't go out of image bounds
    sx = Math.max(0, sx);  // Don't go out of bounds horizontally
    sy = Math.max(0, sy);  // Don't go out of bounds vertically

    // Adjust crop width/height if necessary to stay within the image bounds
    let sw = Math.min(cropWidth, canvas.width - sx);
    let sh = Math.min(cropHeight, canvas.height - sy);

    // If the aspect ratio gets distorted, adjust the height to maintain it
    const currentAspectRatio = sw / sh;
    if (currentAspectRatio > aspectRatio) {
      // Image is too wide, reduce the width
      sw = sh * aspectRatio;
    } else {
      // Image is too tall, reduce the height
      sh = sw / aspectRatio;
    }

    // Create a new canvas for the cropped image
    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = targetWidth;
    croppedCanvas.height = targetHeight;

    const croppedCtx = croppedCanvas.getContext('2d');
    croppedCtx?.drawImage(canvas, sx, sy, sw, sh, 0, 0, targetWidth, targetHeight);

    return croppedCanvas.toDataURL();
  }
  async function processImage(base64Image: string) {
    const targetWidth = 378;
    const targetHeight = 438;

    // Load face-api models (you only need to do this once in your app)
    await loadModels();

    const img = await loadImage(base64Image);
    const orientation = await getOrientation(img);

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    // Correct orientation
    correctOrientation(canvas, img, orientation);

    // Detect face
    const faceBox = await detectFace(img);

    if (faceBox) {
      // Crop to fit the face
      const croppedImageBase64 = cropToFitFace(canvas, faceBox, targetWidth, targetHeight);
      return croppedImageBase64; // Base64 image that fits 32x37, is correctly oriented, and ensures face is not cropped
    }

    // If no face is detected, fall back to basic crop
    return cropToRatio(canvas, targetWidth, targetHeight);
  }

    const formatTimestampToDate = useCallback(function formatTimestampToDate(
    timestamp: number
  ): string {
    const date = new Date(timestamp);

    const days = date.getDate().toString().padStart(2, "0");
    const months = (date.getMonth() + 1).toString().padStart(2, "0");
    const years = date.getFullYear();

    return days + "." + months + "." + years;
  },
  []);

  const p2mm = useCallback(function p2mm(mm: number): number {
    return mm / 2.83465;
  }, []);

  const colors = useMemo(
    (): { [key: string]: [number, number, number] } => ({
      flims: [175, 30, 45],
      laax: [34, 143, 206],
      falera: [233, 69, 56],
      title: [29, 29, 29],
      subtitle: [87, 87, 86],
      names: [255, 255, 255],
      validDate: [60, 60, 59],
      dobBox: [255, 255, 255],
    }),
    []
  );

  /*
The following diagram describes what all sizes actually mean (not to scale):

┌┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┐ `width` and `height` describe the card size + the offset
┆ offset (border that overlaps the card size)                                                            ┆
┆  ┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓  ┆ Card border (`actualWidth` and `actualHeight` are for this. Uses ISO/IEC 7810 ID-1
┆  ┃ padding (space between card border and content                                                   ┃  ┆
┆  ┃  ┌────────────────────────────────────────────────────────────────────────────────────────────┐  ┃  ┆ Up until here: `totalOffset`
┆  ┃  │ Title      ╗                                               ┌┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┐ │  ┃  ┆
┆  ┃  │            ╠ Total height of this: `titleBox`              ┆ Logo (width & height fixed) ┆ │  ┃  ┆
┆  ┃  │ Sub title  ╝                                               └┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┘ │  ┃  ┆
┆  ┃  │ `titleGap` (between titles and photo)                                                      │  ┃  ┆
┆  ┃  │ ┌┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┐                                                                         │  ┃  ┆
┆  ┃  │ ┆ Photo          ┆                                                                         │  ┃  ┆
┆  ┃  │ ┆                ┆                                                                         │  ┃  ┆
┆  ┃  │ ┆ Uses:          ┆                                                                         │  ┃  ┆
┆  ┃  │ ┆ `photo.width`  ┆ The boxes are shifted left by `overlapLeft`                          V  │  ┃  ┆
┆  ┃  │ ┆ `photo.height`┏┷━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓  a  │  ┃  ┆
┆  ┃  │ ┆               ┃ Last name (Has `boxes.paddingHorizontal`, has height `heightName`) ┃  l  │  ┃  ┆
┆  ┃  │ ┆               ┗┯━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛  d  │  ┃  ┆
┆  ┃  │ ┆                ┆ `boxes.gap` describes the gap between boxes                          i  │  ┃  ┆
┆  ┃  │ ┆               ┏┷━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓   t  │  ┃  ┆
┆  ┃  │ ┆               ┃ First name (+ `boxes.paddingHorizontal`, has height `heightName`) ┃   y  │  ┃  ┆
┆  ┃  │ ┆               ┗┯━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛      │  ┃  ┆
┆  ┃  │ ┆                ┆ `boxes.gap` describes the gap between boxes                          d  │  ┃  ┆
┆  ┃  │ ┆               ┏┷━━━━━━━━━━━━━━┓                                                       a  │  ┃  ┆
┆  ┃  │ ┆               ┃ Date of birth ┃ Has height `boxes.heightDob`, bottom aligned          t  │  ┃  ┆
┆  ┃  │ └┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┺━━━━━━━━━━━━━━━┛                                                       e  │  ┃  ┆
┆  ┃  └────────────────────────────────────────────────────────────────────────────────────────────┘  ┃  ┆
┆  ┃                                                                                                  ┃  ┆
┆  ┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛  ┆
┆                                                                                                        ┆
└┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┘

*/

  const offset = 0;

  const sizes = {
    card: {
      actualWidth: 85.6,
      actualHeight: 53.98,
      width: 85.6 + offset * 2,
      height: 53.98 + offset * 2,
      padding: 4,
      titleGap: 1.5,
      titleBox: 13,
    },
    boxes: {
      paddingHorizontal: 1,
      heightName: 5.292,
      heightDob: 3.881,
      gap: 0.5,
      overlapLeft: 2.5,
      maxNameWidth: 42, // 44 minus horizontal padding
    },
    photo: {
      width: 32,
      height: 37,
    },
  };

  const fontSizes = {
    title: 10,
    subtitle: 10,
    names: 12,
    dob: 7,
    validDate: 5,
  };

  const totalOffset = offset + sizes.card.padding;

  const positions = {
    title: {
      x: totalOffset,
      y: totalOffset + p2mm(fontSizes.title),
    },
    subtitle: {
      x: totalOffset,
      y:
        totalOffset +
        (sizes.card.titleBox - sizes.card.titleGap) -
        sizes.card.padding,
    },
    photo: {
      x: totalOffset,
      y: offset + sizes.card.titleBox,
    },
    lastNameBox: {
      x: totalOffset + sizes.photo.width - sizes.boxes.overlapLeft,
      y: null, // Will be calculated along the way
    },
    lastNameText: {
      x:
        totalOffset +
        sizes.photo.width -
        sizes.boxes.overlapLeft +
        sizes.boxes.paddingHorizontal,
      y: null, // Will be calculated along the way
    },
    firstNameBox: {
      x: totalOffset + sizes.photo.width - sizes.boxes.overlapLeft,
      y: null, // Will be calculated along the way
    },
    firstNameText: {
      x:
        totalOffset +
        sizes.photo.width -
        sizes.boxes.overlapLeft +
        sizes.boxes.paddingHorizontal,
      y: null, // Will be calculated along the way
    },
    dobBox: {
      x: totalOffset + sizes.photo.width - sizes.boxes.overlapLeft,
      y:
        sizes.card.height - offset - sizes.card.padding - sizes.boxes.heightDob,
    },
    dobText: {
      x:
        totalOffset +
        sizes.photo.width -
        sizes.boxes.overlapLeft +
        sizes.boxes.paddingHorizontal,
      y:
        sizes.card.height -
        offset -
        sizes.card.padding -
        (sizes.boxes.heightDob - p2mm(fontSizes.dob)) / 2 -
        0.2,
    },
    validText: {
      x: sizes.card.width - totalOffset,
      y: sizes.card.height - totalOffset,
    },
    logo: {
      x: null, // Will be calculated along the way
      y: totalOffset,
    },
  };

  const loadImage = useCallback(function loadImage(path: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = function (err) {
        reject(err);
      };
      img.src = path;
    });
  }, []);

  let isLoading = useMemo(() => false, []);
  let isError = useMemo(() => false, []);

  const createCardPdf = async (users: GetManyUsersResponseElementDTO[]) => {
    try {
      if (isLoading) {
        isLoading = false;
        return;
      }

      const doc = new jsPDF("l", "mm", [sizes.card.width, sizes.card.height]);
      let addedOnce = false;
      isLoading = true;

      const flimsBackground = await loadImage(
        "/pdfImages/backgrounds/flims.png"
      );
      const laaxBackground = await loadImage("/pdfImages/backgrounds/laax.png");
      const faleraBackground = await loadImage(
        "/pdfImages/backgrounds/falera.png"
      );

      const flimsLogo = await loadImage("/pdfImages/logos/flims.png");
      const laaxLogo = await loadImage("/pdfImages/logos/laax.png");
      const faleraLogo = await loadImage("/pdfImages/logos/falera.png");

      for (const user of users) {
        if (!user.validUntil) {
          isLoading = false;
          console.warn("No validUntil date - skipping " + user.id);
          return;
        }

        if (!user.org) {
          console.log(user);
          isLoading = false;
          console.warn("No org - skipping " + user.id);
          return;
        }

        let accentColor;
        let background;
        let logo;
        let logoWidth;
        let logoHeight;
        if (user.org === "Flims") {
          accentColor = colors.flims;
          background = flimsBackground;
          logo = flimsLogo;
          logoWidth = 32;
          logoHeight = 5.5;
        } else if (user.org === "Laax") {
          accentColor = colors.laax;
          background = laaxBackground;
          logo = laaxLogo;
          logoWidth = 25.828;
          logoHeight = 6.051;
        } else {
          accentColor = colors.falera;
          background = faleraBackground;
          logo = faleraLogo;
          logoWidth = 19.569;
          logoHeight = 6.25;
        }

        enum Language {
          DE = "de",
          RM = "rm",
        }

        const localLanguages: Record<string, Language> = {
          Flims: Language.DE,
          Laax: Language.DE,
          Falera: Language.RM,
        };

        const labels: Record<Language, Record<string, string>> = {
          [Language.DE]: {
            title: "Einheimisch-Ausweis",
            subTitle: "Carta da legitimaziun",
            validUntil: "Gültig bis/Valaivla fin ",
          },
          [Language.RM]: {
            title: "Carta da legitimaziun",
            subTitle: "Einheimisch-Ausweis",
            validUntil: "Valaivla fin/Gültig bis",
          },
        };

        let title = labels[localLanguages[user.org]].title;
        let subTitle = labels[localLanguages[user.org]].subTitle;
        if (user.org === "Laax") {
          title = "Carta da legitimaziun";
          subTitle = "Einheimisch-Ausweis";
        }

        if (addedOnce) {
          doc.addPage([sizes.card.width, sizes.card.height], "l");
        } else {
          addedOnce = true;
        }

        doc.addImage(
          background,
          "PNG",
          offset,
          offset,
          sizes.card.actualWidth,
          sizes.card.actualHeight
        );

        /** Titles **/

        doc.setFontSize(fontSizes.title).setFont("HelveticaNowText-Bold");
        doc.setTextColor(...colors.title);
        doc.text(title, positions.title.x, positions.title.y);

        doc.setFontSize(fontSizes.subtitle).setFont("HelveticaNowText-Regular");
        doc.setTextColor(...colors.subtitle);
        doc.text(subTitle, positions.subtitle.x, positions.subtitle.y);

        /** Logo **/

        // TODO: Consolidate with actual image dimensions, crop if needed (it currently just skews the image a bit)
        doc.addImage(
          logo,
          "PNG",
          offset + sizes.card.actualWidth - sizes.card.padding - logoWidth,
          positions.logo.y,
          logoWidth,
          logoHeight
        );

        /** Validity **/

        doc
          .setFontSize(fontSizes.validDate)
          .setFont("HelveticaNowText-Regular");
        doc.setTextColor(...colors.validDate);
        doc.text(
          "Gültig bis/Valaivla fin " + formatTimestampToDate(user.validUntil),
          positions.validText.x,
          positions.validText.y,
          {
            angle: 90,
            rotationDirection: 1,
          }
        );

        /** Photo */
        const photo = await processImage(user.image);

        doc.addImage(
          photo,
          "PNG",
          positions.photo.x,
          positions.photo.y,
          sizes.photo.width,
          sizes.photo.height
        );

        /** Date of birth **/

        doc.setFontSize(fontSizes.dob).setFont("HelveticaNowText-Regular");
        const dobString = formatTimestampToDate(user.dateOfBirth);
        const dobDimensions = doc.getTextDimensions(dobString);

        doc.setFillColor(...colors.dobBox);
        doc.rect(
          positions.dobBox.x,
          positions.dobBox.y,
          dobDimensions.w + sizes.boxes.paddingHorizontal * 2,
          sizes.boxes.heightDob,
          "F"
        );
        doc.setTextColor(...colors.title);
        doc.text(dobString, positions.dobText.x, positions.dobText.y);

        /** Names **/

        doc.setFontSize(fontSizes.names).setFont("HelveticaNowText-Regular");
        const firstNameDimensions = doc.getTextDimensions(user.firstname);

        doc.setFontSize(fontSizes.names).setFont("HelveticaNowText-Bold");
        const lastNameDimensions = doc.getTextDimensions(user.lastname);

        let namesFontSize = fontSizes.names;
        let namesBoxHeight = sizes.boxes.heightName;
        if (
          firstNameDimensions.w > sizes.boxes.maxNameWidth ||
          lastNameDimensions.w > sizes.boxes.maxNameWidth
        ) {
          // At least one of the names is too long to fit, figure out the larger one and adjust
          // the font size accordingly. It should fit 42mm. The name has a size ratio of fontSize / width,
          // so new fontsize = (fontSize / width) * 42
          // The box size can be handled the same. We use the box size to position the stuff afterwards, too.
          const longestNameWidth = Math.max(
            firstNameDimensions.w,
            lastNameDimensions.w
          );
          const sizeRatio = fontSizes.names / longestNameWidth;

          namesFontSize = sizeRatio * sizes.boxes.maxNameWidth;
          namesBoxHeight =
            sizes.boxes.heightName * (namesFontSize / fontSizes.names);
        }

        const nameTextBoxOffsetInner = namesBoxHeight - p2mm(namesFontSize) / 4;
        const firstNameBoxY =
          positions.dobBox.y - sizes.boxes.gap - namesBoxHeight;
        const lastNameBoxY = firstNameBoxY - sizes.boxes.gap - namesBoxHeight;
        doc.setFontSize(namesFontSize).setFont("HelveticaNowText-Regular");
        const newFirstNameDimensions = doc.getTextDimensions(user.firstname);
        doc.setFontSize(namesFontSize).setFont("HelveticaNowText-Bold");
        const newLastNameDimensions = doc.getTextDimensions(user.lastname);

        doc.setFillColor(...accentColor);
        doc.rect(
          positions.firstNameBox.x,
          firstNameBoxY,
          newFirstNameDimensions.w + sizes.boxes.paddingHorizontal * 2,
          namesBoxHeight,
          "F"
        );
        doc.setFontSize(namesFontSize).setFont("HelveticaNowText-Regular");

        doc.setTextColor(...colors.names);
        doc.text(
          user.firstname,
          positions.firstNameText.x,
          firstNameBoxY + nameTextBoxOffsetInner
        );

        doc.setFillColor(...accentColor);
        doc.rect(
          positions.lastNameBox.x,
          lastNameBoxY,
          newLastNameDimensions.w + sizes.boxes.paddingHorizontal * 2,
          namesBoxHeight,
          "F"
        );
        doc.setFontSize(namesFontSize).setFont("HelveticaNowText-Bold");

        doc.setTextColor(...colors.names);

        doc.text(
          user.lastname,
          positions.lastNameText.x,
          lastNameBoxY + nameTextBoxOffsetInner
        );
      }
      doc.save("cards.pdf");
      isLoading = false;
      return;
    } catch (error) {
      console.error(error);
      isLoading = false;
      isError = true;
    }
  };

  return {
    createCardPdf,
    isLoading,
    isError,
  };
};
