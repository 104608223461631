import imageCompression from "browser-image-compression";

const useDownscale = () => {
  async function scaleCropImage(
    file: File,
    desiredWidth = 413,
    desiredHeight = 532
  ): Promise<File> {
    return imageCompression(file, {
      maxWidthOrHeight:
        desiredHeight > desiredWidth ? desiredHeight : desiredWidth,
      useWebWorker: true,
      maxSizeMB: 2,
    });
  }

  return { scaleCropImage };
};

export default useDownscale;
