import { MenuItem, TextField } from "@mui/material";
import { roles } from "./labels";
import { FormikProps } from "formik";
import { GetOneUserResponseDTO } from "../redux/usersApi";

export interface RoleProps {
  formik: FormikProps<Partial<GetOneUserResponseDTO>>;
  isSuperAdmin: boolean;
  isBulkEdit?: boolean;
}

const RoleSelect = (props: RoleProps) => {

    console.log(props.formik.values);
  return (
    <TextField
      error={
        props.formik.touched?.role && !!props.formik.errors.role
      }
      fullWidth
      helperText={
        props.formik.touched?.role && props.formik.errors.role
      }
      InputProps={{ disableUnderline: true }}
      label="Role"
      name="role"
      onChange={(e) => {
        props.formik.setFieldValue(e.target.name, e.target.value, false);
        props.formik.setFieldTouched(e.target.name, true, false);
      }}
      required
      select
      SelectProps={{ native: false }}
      value={props.formik.values.role ?? ""}
      variant="filled"
    >
      {Object.entries(roles).map(([key, value]) => {
        return (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default RoleSelect;
