import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { UpdateUserResponseDTO } from "../redux/usersApi";

interface DeleteForeverDialogProps {
  isLoading: boolean;
  deleteForever: (id: string) => void;
  open: boolean;
  handleClose: () => void;
  user: Omit<
    UpdateUserResponseDTO,
    "applicationStatus" | "paymentStatus" | "createdAt" | "awaitingReply"
  > & { id: string };
}

const DeleteForeverDialog = (props: DeleteForeverDialogProps) => {
  const dateOfBirth = new Date(props.user.dateOfBirth).toLocaleDateString(
    "de-DE"
  );
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        fullWidth
      >
        <DialogTitle>Antrag Löschen</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Möchtest du den Antrag von {props.user.firstname}{" "}
            {props.user.lastname} (Geburtsdatum: {dateOfBirth}) wirklich
            löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Abbrechen</Button>
          <LoadingButton
            variant="contained"
            loading={props.isLoading}
            onClick={() => {
              props.deleteForever(props.user.id);
            }}
          >
            Löschen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteForeverDialog;
