import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UpdateUserRequestDTO } from "../redux/usersApi";
import DiffTable from "./DiffTable";

interface BulkSaveDialogProps {
  length: number;
  oldValues: Partial<
    Pick<UpdateUserRequestDTO, "applicationStatus" | "cardStatus">
  >;
  newValues: Partial<
    Pick<UpdateUserRequestDTO, "applicationStatus" | "cardStatus">
  >;
  open: boolean;
  handleClose: () => void;
  handleSave: (newValues: Partial<UpdateUserRequestDTO>) => void;
  isLoading: boolean;
}

export default function BulkSaveDialog(props: BulkSaveDialogProps) {
  const handleCloseDialog = () => {
    props.handleClose();
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>Änderungen speichern</DialogTitle>
        <DialogContent>
          <DiffTable oldValues={props.oldValues} newValues={props.newValues} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Abbrechen</Button>
          <LoadingButton
            variant="contained"
            loading={props.isLoading}
            onClick={async () => {
              props.handleSave(props.newValues);
            }}
          >
            {props.length} Benutzer anpassen
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
