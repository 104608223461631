import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { UpdateUserRequestDTO } from "../redux/usersApi";
import { UserFormFields } from "../typings/global";
import DiffTable from "./DiffTable";

interface SaveDialogProps {
  oldValues: Partial<Pick<UpdateUserRequestDTO, keyof UserFormFields>>;
  newValues: Partial<Omit<UpdateUserRequestDTO, "org">>;
  open: boolean;
  handleClose: () => void;
  handleSave: (newValues: Partial<UpdateUserRequestDTO>) => void;
  isLoading: boolean;
}

export default function SaveDialog(props: SaveDialogProps) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        fullWidth
      >
        <DialogTitle>Änderungen speichern</DialogTitle>
        <DialogContent>
          <DiffTable oldValues={props.oldValues} newValues={props.newValues} />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Abbrechen</Button>
          <LoadingButton
            variant="contained"
            loading={props.isLoading}
            onClick={async () => {
              props.handleSave(props.newValues);
            }}
          >
            Speichern
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
