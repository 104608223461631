import { Box } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { UpdateUserRequestDTO } from "../redux/usersApi";
import { ApplicationStatus, CardStatus, Title } from "../typings/enums";
import { UserFormFields } from "../typings/global";
import {
  applicationStatusLabels,
  cardStatusLabels,
  titleLabels,
} from "./labels";

const fieldNames: Record<keyof UserFormFields, string> = {
  firstname: "Vorname",
  lastname: "Nachname",
  dateOfBirth: "Geburtsdatum",
  title: "Anrede",
  address: "Adresse",
  postalCode: "Postleitzahl",
  email: "E-Mail",
  phone: "Telefon",
  comment: "Kommentar",
  cardId: "Card ID",
  image: "Bild",
  cardStatus: "Kartenstatus",
  applicationStatus: "Antragsstatus",
  role: "Rolle",
  orgAdmin: "Organisationsadministrator",
};

type FormValues = Partial<Pick<UpdateUserRequestDTO, keyof UserFormFields>>;

export interface DiffTableProps {
  oldValues: FormValues;
  newValues: FormValues;
}

export default function DiffTable(props: DiffTableProps) {
  const rows = Object.entries(props.newValues)
    // Filter out empty values
    .filter(([_key, value]) => value)
    .map(([key, value]) => {
      const { oldValues } = props;

      if (!fieldNames[key as keyof UserFormFields]) {
        throw new Error(`Unknown key ${key}`);
      }

      const parsedNewValue =
        key === "dateOfBirth" && typeof key == "string"
          ? new Date(value)
          : value;

      const formatIfDate = (newValue: string | number | Date): string => {
        if (newValue instanceof Date) {
          return newValue.toLocaleDateString("de-CH", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
        }
        return newValue.toString();
      };

      const formatIfCardStatus = (newValue: string): string => {
        const isCardStatus = (value: unknown): value is CardStatus =>
          Object.values(CardStatus).includes(value as CardStatus);
        const isApplicationStatus = (
          value: unknown
        ): value is ApplicationStatus =>
          Object.values(ApplicationStatus).includes(value as ApplicationStatus);
        const isTitle = (value: unknown): value is Title =>
          Object.values(Title).includes(value as Title);

        if (isCardStatus(newValue)) {
          return cardStatusLabels[newValue];
        }
        if (isApplicationStatus(newValue)) {
          return applicationStatusLabels[newValue];
        }
        if (isTitle(newValue)) {
          return titleLabels[newValue];
        }

        return newValue;
      };

      const oldValue =
        key === "dateOfBirth" && typeof key == "string"
          ? new Date(oldValues[key as keyof UserFormFields] as string)
          : oldValues[key as keyof UserFormFields];

      return {
        id: key,
        path: fieldNames[key as keyof UserFormFields],
        old: oldValue ? formatIfCardStatus(formatIfDate(oldValue)) : "",
        new: formatIfCardStatus(formatIfDate(parsedNewValue)),
      };
    });

  return (
    <DataGrid
      hideFooter
      autoHeight
      getRowHeight={() => "auto"}
      rowSelection={true}
      columns={[
        {
          field: "path",
          headerName: "Feld",
          type: "string",
          flex: 1,
        },
        {
          field: "old",
          headerName: "Alt",
          type: "string",
          flex: 1,
          renderCell: (props: GridRenderCellParams) => {
            if (props.row.path === "Bild") {
              return (
                <Box className="p-4">
                  <img className="rounded-lg" src={props.row.old as string} />
                </Box>
              );
            }
            return <span>{props.value}</span>;
          },
        },
        {
          field: "new",
          headerName: "Neu",
          type: "string",
          flex: 1,
          renderCell: (props: GridRenderCellParams) => {
            if (props.row.path === "Bild") {
              return (
                <Box className="p-4">
                  <img className="rounded-lg" src={props.row.new as string} />
                </Box>
              );
            }

            return <span className="overflow-auto p-3">{props.value}</span>;
          },
        },
      ].map((column) => ({
        ...column,
        cellClassName: "py-3",
      }))}
      rows={rows}
    />
  );
}
