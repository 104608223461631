import { AuthProvider } from "./components/AuthContext";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {AuthContext} from "./components/AuthContext";
import { useContext } from "react";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import TokenValidation from "./pages/TokenValidation";

export default function App() {
  const authContext = useContext(AuthContext);
    const token = authContext?.token; // This assumes you've added userId to your AuthContext

    const router = createBrowserRouter(
    [
      {
        path: "/dashboard",
        element: <Dashboard key={token} />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/forgotpassword/:token",
        element: <TokenValidation />,
      },
    ],
    {
      basename: import.meta.env.VITE_BASE_URL,
    }
  );

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
