import { Grid, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent } from "react";
import { GetOneUserResponseDTO } from "../redux/usersApi";
import { CardStatus } from "../typings/enums";
import CardStatusSelect from "./CardStatusSelect";

export interface CardFormProps {
    isSuperAdmin: boolean;
    isCardIssuer: boolean;
    formik: FormikProps<Partial<GetOneUserResponseDTO>>;
    handleFieldChange: (event: ChangeEvent<HTMLInputElement>) => void;
    validUntil: Date | null;
    transactionId: string | null;
    transactionDate: Date | null;
}

// Format the Card ID by extracting the value between two '$' symbols
const formatCardId = (input: string): string => {
    // Use regex to extract the content between two '$' symbols
    const match = input.match(/\$(.*?)\$/);
    return match ? match[1] : input; // Return the match or the original input if no match is found
};

export const CardForm = (props: CardFormProps) => {
    if (props.isSuperAdmin) {
        return null;
    }

    if (!props.formik.values.cardStatus) {
        return null;
    }

    const handleCardIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        const formattedValue = formatCardId(event.target.value);

        // Update formik's cardId field with the formatted value
        props.handleFieldChange(event);
        props.formik.setFieldValue("cardId", formattedValue);
    };

    return (
        <>
            {props.transactionId && (
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Transaktionsdatum"
                        InputProps={{ disableUnderline: true }}
                        value={
                            props.transactionDate !== null
                                ? props.transactionDate.toLocaleDateString("de-DE")
                                : ""
                        }
                        variant="filled"
                        fullWidth
                    />
                </Grid>
            )}
            {props.transactionId && (
                <Grid item xs={6}>
                    <TextField
                        disabled
                        label="Transaktions-ID"
                        InputProps={{ disableUnderline: true }}
                        value={props.transactionId}
                        variant="filled"
                        fullWidth
                    />
                </Grid>
            )}
            <Grid item xs={6}>
                <TextField
                    disabled={!props.isCardIssuer}
                    error={!!props.formik.touched.cardId && !!props.formik.errors.cardId}
                    fullWidth
                    helperText={props.formik.touched.cardId && props.formik.errors.cardId}
                    InputProps={{ disableUnderline: true }}
                    label="Card ID"
                    name="cardId"
                    onChange={handleCardIdChange}
                    required={[CardStatus.Assigned, CardStatus.Sent].includes(
                        props.formik.values.cardStatus
                    )}
                    value={props.formik.values.cardId ?? ""}
                    variant="filled"
                />
            </Grid>
            <Grid item xs={6}>
                <CardStatusSelect
                    formik={props.formik}
                    isCardIssuer={props.isCardIssuer}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled
                    label="Gültig bis (5 Jahren ab Genehmigung Antrag)"
                    InputProps={{ disableUnderline: true }}
                    value={
                        props.validUntil !== null
                            ? props.validUntil.toLocaleDateString("de-DE")
                            : ""
                    }
                    variant="filled"
                    fullWidth
                />
            </Grid>
        </>
    );
};
