import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Container, Box, Typography, CssBaseline, Avatar } from '@mui/material';
import { LockReset } from '@mui/icons-material';

interface ResetPasswordFormProps {
    token: string;
    userName: string;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ token, userName }) => {
    const [password, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setErrorMessage('Passwörter stimmen nicht überein. Bitte überprüfen Sie Ihre Eingabe.');
            return;
        }

        try {
            await axios.post(`${import.meta.env.VITE_API_URL}users/forgotpassword/reset`, {
                token,
                userName,
                password,
            });
            navigate('/'); // Navigate to root or consider a "success" route
        } catch (error) {
            setErrorMessage('Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es erneut.');
            console.error('Reset password error:', error);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh', // Min-height is 100% of viewport height
                }}
            >
                <Box
                    sx={{
                        my: 'auto', // Margins top and bottom auto for vertical centering
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockReset />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Passwort zurücksetzen
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Neues Passwort"
                            type="password"
                            id="password"
                            value={password}
                            onChange={e => setNewPassword(e.target.value)}
                            autoComplete="new-password"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Neues Passwort bestätigen"
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            autoComplete="new-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Passwort zurücksetzen
                        </Button>
                        {errorMessage && (
                            <Typography color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default ResetPasswordForm;
