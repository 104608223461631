import { Chip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLogicOperator,
  GridRowId,
  deDE,
} from "@mui/x-data-grid";
import {
  GetManyUsersResponseElementDTO,
  GetMeResponseDTO,
  useLazyGetUserQuery,
} from "../redux/usersApi";
import { ApplicationStatus, CardStatus, Role, PaymentStatus } from "../typings/enums";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {applicationStatusLabels, cardStatusLabels, paymentStatusLabels} from "./labels";
import { useEffect } from "react";

interface UserTableProps {
  rows: GetManyUsersResponseElementDTO[];
  fetchUser: ReturnType<typeof useLazyGetUserQuery>[0];
  selectedUsers: string[];
  loading?: boolean;
  setRowSelectionModel: (rowSelectionModel: string[]) => void;
  loggedInUser?: GetMeResponseDTO;
}

const UsersTable = (props: UserTableProps) => {
  const columns: GridColDef[] = [
    { field: "comment", filterable: true, headerName: "Interner Kommentar" },
    { field: "org", filterable: true, headerName: "Gemeinde" },
    { field: "postalCode", filterable: true, headerName: "PLZ" },
    { field: "cardId", filterable: true, headerName: "Card ID" },
    { field: "email", filterable: true, headerName: "E-Mailadresse" },
    { field: "address", filterable: true, headerName: "Adresse" },
    { field: "firstname", headerName: "Vorname", width: 150, type: "string" },
    { field: "lastname", headerName: "Nachname", width: 150, type: "string" },
    {
      field: "dateOfBirth",
      headerName: "Geburtsdatum",
      width: 140,
      type: "date",
      valueGetter: (params) => new Date(params.value),
    },
    {
      type: "singleSelect",
      field: "paymentStatus",
      headerName: "Zahlungsstatus",
      width: 140,
      renderCell: (params) => {
        const color =
            params.value === PaymentStatus.Paid
                ? "success"
                : params.value === PaymentStatus.Unpaid
                    ? "error"
                    : "warning";
        return <Chip color={color} label={params.formattedValue} />;
      },
      valueOptions: Object.values(PaymentStatus).map((status) => {
        return {
          value: status,
          label: paymentStatusLabels[status],
          key: status,
        };
      }),
      // ValueOptions type is incorrect
      // Should be overriden in global.d.ts
      // or a pull request should be made to @mui/x-data-grid
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (option: any) => {
        return option?.value as ApplicationStatus;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (option: any) => option?.label as string,
    },    {
      field: "cardStatus",
      headerName: "Kartenstatus",
      width: 150,
      renderCell: (params) => {
        return <Chip color={"primary"} label={params.formattedValue} />;
      },
      type: "singleSelect",
      valueOptions: Object.values(CardStatus).map((status) => {
        return {
          value: status,
          label: cardStatusLabels[status],
          key: status,
        };
      }),
      // ValueOptions type is incorrect
      // Should be overriden in global.d.ts
      // or a pull request should be made to @mui/x-data-grid
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (option: any) => {
        return option?.value as CardStatus;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (option: any) => option?.label as string,
    },
    {
      type: "singleSelect",
      field: "applicationStatus",
      headerName: "Antragsstatus",
      width: 140,
      renderCell: (params) => {
        const color =
          params.value === ApplicationStatus.Approved
            ? "success"
            : params.value === ApplicationStatus.Pending
            ? "warning"
            : "error";
        return <Chip color={color} label={params.formattedValue} />;
      },
      valueOptions: Object.values(ApplicationStatus).map((status) => {
        return {
          value: status,
          label: applicationStatusLabels[status],
          key: status,
        };
      }),
      // ValueOptions type is incorrect
      // Should be overriden in global.d.ts
      // or a pull request should be made to @mui/x-data-grid
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionValue: (option: any) => {
        return option?.value as ApplicationStatus;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getOptionLabel: (option: any) => option?.label as string,
    },
  ];

  const searchQuery = useSelector((state: RootState) => state.ui.searchQuery);

  const { setRowSelectionModel, selectedUsers } = props;
  useEffect(() => {
    if (searchQuery.length > 0 && selectedUsers.length > 0) {
      setRowSelectionModel([]);
    }
    // Should deselect only when on query change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleRowClick = (params: { id: GridRowId }) => {

    props.fetchUser(params.id.toString());
  };

  return (
      <DataGrid
          disableColumnFilter
          filterModel={{
            items: ["firstname"].map((field, idx) => ({
              field,
              operator: "contains",
              id: `${idx}_${field}`,
            })),
            quickFilterLogicOperator: GridLogicOperator.And,
            quickFilterValues: searchQuery.split(" "),
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          loading={props.loading}
          autoPageSize={true}
          disableRowSelectionOnClick
          rowSelectionModel={props.selectedUsers}
          checkboxSelection
          sx={{
            flexGrow: 1, // Allows the DataGrid to grow
            height: "100%", // Full height
            minHeight: "90vh", // Minimum height to cover most of the screen
          }}
          onRowClick={handleRowClick}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            if (newRowSelectionModel.length === 1) {
              handleRowClick({ id: newRowSelectionModel[0] });
            }
            const newRowSelectionModelAsStringArray = newRowSelectionModel.map(id => id.toString());
            props.setRowSelectionModel(newRowSelectionModelAsStringArray);
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                comment: false,
                org: false,
                postalCode: false,
                cardId: false,
                email: false,
                address: false,
                applicationStatus: props.loggedInUser?.role !== Role.CardIssuer,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "lastname",
                  sort: "asc",
                },
              ],
            },
          }}
          rows={props.rows}
          columns={columns}
      />
  );
};

export default UsersTable;
