import { ContentCopy } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

interface CreateUserSuccessDialogProps {
  oneTimePassword: string;
  open: boolean;
  handleClose: () => void;
}

export default function CreateUserSuccessDialog(
  props: CreateUserSuccessDialogProps
) {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => {
          props.handleClose();
        }}
        fullWidth
      >
        <DialogContent>
          <Typography fontSize={20}>
            Neuer Antrag erfolgreich erfasst. Das vorläufige Passwort ist nur
            für den ersten Login gültig. Bitte geben Sie es dem neuen Benutzer
            bekannt. Wenn sie darauf klicken, wird es in die Zwischenablage
            kopiert.{" "}
          </Typography>
          <Box className="flex-auto flex align-middle justify-center pt-10 pb-7">
            <Button
              variant="outlined"
              sx={{ textTransform: "none", paddingBlock: 4, paddingInline: 6 }}
              onClick={() => copyToClipboard(props.oneTimePassword)}
              endIcon={<ContentCopy />}
            >
              <Typography fontSize={40}>{props.oneTimePassword}</Typography>
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Schliessen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
