import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: {
    selectedUserId: null as null | string,
    selectedUsers: [] as string[],
  },
  reducers: {
    setSelectedUser: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        selectedUserId: action.payload,
      };
    },
    setSelectedUsers: (state, action: PayloadAction<string[]>) => {
      return {
        ...state,
        selectedUsers: action.payload,
      };
    },
  },
});

export const { setSelectedUser, setSelectedUsers } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
