import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import { ChangeEvent } from "react";
import { GetOneUserResponseDTO } from "../redux/usersApi";
import RoleSelect from "./RoleSelect";
import OrgSelect from "./OrgSelect";
export interface AdminFormProps {
  isSuperAdmin: boolean;
  formik: FormikProps<Partial<GetOneUserResponseDTO>>;
  orgs: string[];
  handleFieldChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AdminForm = (props: AdminFormProps) => {
    if(!props.isSuperAdmin) {
       return null;
    }

  return (
    <>
      <Grid item xs={6}>
        <RoleSelect
          formik={props.formik}
          isSuperAdmin={props.isSuperAdmin}
        />
      </Grid>
        <Grid item xs={6}>
          <OrgSelect
              formik={props.formik}
              orgs={props.orgs}
              isSuperAdmin={props.isSuperAdmin}
          />
      </Grid>
    </>
  );
};
