import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { store } from "./redux/store.ts";

// Import ThemeProvider and your custom theme
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme"; // Adjust path based on your structure

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}> {/* Wrap with ThemeProvider */}
                <App />
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);
