import GridLayout from "../components/GridLayout";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import MenuBar from "../components/MenuBar";

export default function Dashboard() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#315952",
      },
    },
  });


  return (
      <ThemeProvider theme={theme}>
        <MenuBar />
        <Container maxWidth={false} style={{ maxWidth: "100%", padding: 0 }} className="p-3">
          <CssBaseline />
          <GridLayout />
        </Container>
      </ThemeProvider>
  );
}
