import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "100%", // Set the container's max width to 100%
        },
      },
    },
  },
});

export default theme;
