import {
  ApplicationStatus,
  CardStatus,
  PaymentStatus,
  Title,
  Role,
} from "../typings/enums";

export const cardStatusLabels: Record<CardStatus, string> = {
  [CardStatus.NotPrinted]: "Nicht Gedruckt",
  [CardStatus.Printed]: "Gedruckt",
  [CardStatus.Assigned]: "ID Zugewiesen",
  [CardStatus.Sent]: "Versendet",
};

export const roles: Record<Role, string> = {
  [Role.Tenant]: "Tenant",
  [Role.CardIssuer]: "CardIssuer",
  [Role.SuperAdmin]: "SuperAdmin",
};

export const applicationStatusLabels: Record<ApplicationStatus, string> = {
  [ApplicationStatus.Approved]: "Genehmigt",
  [ApplicationStatus.Pending]: "Ausstehend",
  [ApplicationStatus.Rejected]: "Abgelehnt",
  [ApplicationStatus.Draft]: "Entwurf",
};

export const paymentStatusLabels: Record<PaymentStatus, string> = {
  [PaymentStatus.Paid]: "Bezahlt",
  [PaymentStatus.Unpaid]: "Unbezahlt",
  [PaymentStatus.InProgress]: "In Bearbeitung",
};

export const titleLabels: Record<Title, string> = {
  [Title.Woman]: "Frau",
  [Title.Man]: "Herr",
  [Title.Divers]: "Divers",
};

export const paymentLabels: Record<PaymentStatus, string> = {
  [PaymentStatus.Paid]: "Bezahlt",
  [PaymentStatus.Unpaid]: "Unbezahlt",
  [PaymentStatus.InProgress]: "Zahlung in Bearbeitung",
};

export const awaitingReplyLabels = {
  true: "Offene Rückfrage",
  false: "Keine offene Rückfrage",
};
